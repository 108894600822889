<script setup lang="ts">
import { z } from 'zod';
import AppCopyButton from '~/components/app/AppCopyButton.vue';

const userStore = useUserStore();
const { user, profile } = storeToRefs(userStore);
const form = ref(null);
const buttonState = ref('ready');

const state = reactiveComputed(() => {
  return {
    fullName: profile.value?.fullName ?? undefined,
    email: user.value?.email ?? undefined,
  };
});

const validate = (state: any): FormError[] => {
  const errors = [];
  if (!state.fullName) errors.push({ path: 'fullName', message: 'Required' });

  const emailSchema = z.string().email();
  const emailValidation = emailSchema.safeParse(state.email);
  if (!emailValidation.success) {
    errors.push({ path: 'email', message: 'Invalid email address' });
  }

  return errors;
};

const onSubmit = async (event: FormSubmitEvent) => {
  buttonState.value = 'loading';
  const { data: metadata } = event;
  const updates = { ...metadata };
  const { data: response } = await useApi(`/api/users/validate`, {
    method: 'POST',
    body: { email: updates.email },
  });

  if (response.value?.status === 'error') {
    buttonState.value = 'error';
    form.value.errors.push({
      path: 'email',
      message: 'Email already exists',
    });
    return;
  } else {
    await userStore.updateProfile(updates);
    buttonState.value = 'success';
  }

  setTimeout(() => {
    buttonState.value = 'ready';
  }, 2000);
};
</script>
<template>
  <NuxtLayout name="account">
    <UForm
      ref="form"
      :validate="validate"
      :state="state"
      class="w-full mb-xl flex flex-col gap-6 items-start"
      @submit="onSubmit"
    >
      <p class="main-title">My Profile</p>
      <UFormGroup
        eager-validation
        size="xl"
        name="fullName"
        required
        label="Name"
        class="w-full"
      >
        <UInput v-model="state.fullName" />
      </UFormGroup>
      <UFormGroup
        eager-validation
        size="xl"
        required
        name="email"
        label="Email"
        class="w-full"
      >
        <UInput v-model="state.email" />
      </UFormGroup>
      <UFormGroup size="xl" name="apiKey" label="API Key" class="w-full">
        <UButtonGroup
          size="xl"
          orientation="horizontal"
          :ui="{ wrapper: { horizontal: 'w-1/2' } }"
        >
          <UInput
            readonly
            :model-value="user?.apiKey"
            :ui="{ wrapper: 'w-full' }"
          />
          <AppCopyButton :text="user?.apiKey" />
        </UButtonGroup>
      </UFormGroup>

      <UButton
        :ui="{
          rounded: 'rounded-full',
          font: 'font-bold',
          variant: {
            solid: 'transition-colors duration-150',
          },
        }"
        size="xl"
        :color="
          buttonState === 'success'
            ? 'green'
            : buttonState === 'error'
              ? 'red'
              : 'primary'
        "
        :leading-icon="
          buttonState === 'success'
            ? 'i-ph-check-circle'
            : buttonState === 'error'
              ? 'i-ph-x-circle'
              : undefined
        "
        :label="
          buttonState === 'loading'
            ? 'Saving...'
            : buttonState === 'success'
              ? 'Saved'
              : buttonState === 'error'
                ? 'Error'
                : 'Save Changes'
        "
        variant="solid"
        :loading="buttonState === 'loading'"
        type="submit"
        :disabled="form?.errors?.length"
        class="ml-auto"
      />
    </UForm>
  </NuxtLayout>
</template>
